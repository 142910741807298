//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import selectInfo from "./selectInfo.js";
import { commonQuery as commonQueryValidator } from "../validatorConfig/commonQuery";
export default {
  extends: selectInfo,
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "MealCityDictVal",
      "MealProvinceDictVal",
      "mettingType",
      "MealCityGPS"
    ])
  },
  methods: {
    ...mapActions(["GetOpenMealUrlPromise", "GetOrderUrl"]),
    selectCity() {
      const currentRoute = this.$route.path;
      this.$router.push({
        path: "/selectCity",
        query: {
          mealQueryType: 2,
          backRoute: currentRoute
        }
      });
    },
    async search() {
      var params = {
        cityDictVal: this.MealCityDictVal,
        // typeMeetVal: this.mettingType.val
        tenantId: this.iStorage.get("tenantId")
      };
      const validateResult = this.validate(params, commonQueryValidator);
      if (!validateResult) return;
      let queryType = this.iStorage.get("queryType") || "meal";
      switch (queryType) {
        case "meal":
          this.mealSearch(params);
          break;
        case "venue":
          this.venueSearch();
          break;
        case "car":
          this.carSearch();
          break;
        case "iEvent":
          this.getOrderUrl({
            url: "GetMealOrderUrl",
            itemId: null
          });
          break;
      }
    },
    //获取外部站点url
    async getOrderUrl(options) {
      console.log(options)
      let params = {
        latitude: this.MealCityGPS.latitude,
        longitude: this.MealCityGPS.longitude
      };
      let response = await this.GetOrderUrl(params);
      if (response && response.success) {
        if (response.content.hasOwnProperty("url")) {
          this.openDianPing(response.content.url);
        } else {
          this.openDianPing(response.content);
        }
      }
    },
    //打开外部站点
    openDianPing(url) {
      console.log(url);
      const options = {
        method: "SMGeneral.openNewWebSiteForResult",
        postData: {
          loadUrl: url,
          browserMode: {
            navBarVisibilty: true,
            titleTextColor: "#ffffff",
            navBackgroundColor: "#2D82F0"
          }
        },
        callback: function() {}
      };
      this.JSbridge.call(options);
    },
    urlQuy(url) {
      let query = url.split('?')
			let queryObj = {}
			query[1].split('&').forEach(item => {
        let itemArr = item.split("=")
        queryObj[itemArr[0]] = itemArr[1]
      })
      return queryObj
    },
    async mealSearch(params) {
      const res = await this.GetOpenMealUrlPromise(params);
      if (res && res.success) {
        let mshopUrl = res.content;
        const rnd = new Date().getTime();
        mshopUrl += "&action=search&qshop=true&rejecttabs=0,2" + "&rnd=" + rnd;
        let urlObj = this.urlQuy(mshopUrl)
        let appType = this.iStorage.get("appType");
        if (appType == "iEvent") {
          urlObj.appfrom = 'ievent'
        }
        this.$router.push({
          path: "/index",
          query: {
            ...urlObj
          }
        });
        this.iStorage.set("Selected", "2");
      }
    },
    venueSearch() {
      const ivenueUrl =
        this.iStorage.get("Uris")["Uris"]["webapp-ivenue"] + "/venueList";
      this.openWebapp(ivenueUrl);
    },
    carSearch() {
      const icarUrl =
        this.iStorage.get("Uris")["Uris"]["webapp-icar"] + "/item";
      this.openWebapp(icarUrl);
    },
    openWebapp(loadUrl) {
      const urlParams = location.href.substring(location.href.indexOf("?"));
      this.$cookies.setCookie(
        "eventData",
        JSON.stringify({
          cityDictVal: this.MealCityDictVal,
          province: this.MealProvinceDictVal
        }),
        {
          domain: window.idomain
        }
      );
      this.JSbridge.openWebapp({
        loadUrl: loadUrl + urlParams
      });
    }
  }
};
