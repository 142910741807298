import { mapGetters, mapMutations, mapActions } from 'vuex' 
import { datePicker } from '@/utils/util'
import toast from '@/utils/toast'
export default {
    data(){
        return {
            country: '中国',
            province: '',
            city: '',
            gps: null,
            slots: null,
            results: null,
            showPicker: false,
            popupType: '',
            popupTypeText: '',
            popupTitle: '',
            mealQueryType: this.iStorage.get('mealQueryType')
        }
    },
    computed: {
        ...mapGetters([
            'takeoutProvince',
            'takeoutCity',
            'mealProvince',
            'mealCity',
            'address',
            'selectDate',
            'time'
        ])
    },
    watch: {
        'selectDate'(val, oldVal) {
            console.log(oldVal)
            if(val) {
                this.setTakeoutParams({key:'time',value:{}})
            }
        }
    },
    methods: {
        ...mapMutations([
            'setMealParams',
            'setTakeoutParams',
            'setProvinceCity'           
        ]),
        ...mapActions([
            'QueryDoCityPromise', 
            'GetMeetingTypesPromise', 
            'QueryAddressesPromise',
            'QueryTimelistPromise'
        ]),
        selectAddress() {
            this.Handle('address')
        },
        selectTime() {
            this.Handle('time')
        },        
        async Handle (type) {
            this.initProvinceCity()            
            if(type === 'city' && !this.province) {
                toast('请选择省份')
                return
            }               
            this.popupHandle()
            if (type === 'province') {
            let params = {
                "keyCode":"CPC-Province",
                "lnkVal":"中国"
            }
            let res = await this.QueryDoCityPromise(params)
            if(res && res.success) {
                this.results = res.content
            }
            this.popupTypeText = '请选择省份'
            }
            if (type === 'city') {   
            let  params = {
                "keyCode":"CPC-City",
                "lnkVal":this.province
            }
            let res = await this.QueryDoCityPromise(params)
            if(res && res.success) {
                this.results = res.content
            }
            this.popupTypeText = '请选择城市'
            } 
            if (type === 'mettingType') {
                let  params = {
                    "CityName": this.MealCityDictVal
                }
                let res = await this.GetMeetingTypesPromise(params)
                if(res && res.success) {
                    this.results = res.result
                }
                this.popupTypeText = '请选择会议类型'
            }
            if (type === 'address') {
                let  params = {
                    "city": this.TakeoutCityDictVal
                }
                let res = await this.QueryAddressesPromise(params)
                if(res && res.success) {
                    this.results = res.result
                }
                this.popupTypeText = '请选择送餐地点'
            }                                         
            if (type === 'date') {
            var minDate = new Date()//会议时间前3天
            const min = [minDate.getFullYear(), minDate.getMonth() + 1, minDate.getDate()]
            const max = [minDate.getFullYear()+1, 12, 31]
            const data = this.ymd = datePicker(min, max)                   
            this.slots = [
                {
                flex: 1,
                values: data.map(item => item.value),
                textAlign: 'center'
                }, {
                flex: 1,
                values: data[0].children.map(item => item.value),
                textAlign: 'center'
                }, {
                flex: 1,
                values: data[0].children[0].children.map(item => item.value),
                textAlign: 'center'
                }
            ]
            this.popupTitle = '请选择送餐日期'
            }
            if (type === 'time') {
                    let params = {
                        sendDate: this.selectDate,
                        externalId: this.address.val
                    }
            let res = await this.QueryTimelistPromise(params)
            if(res && res.success) {
                this.results = res.result.data
            }          
            this.popupTypeText = '请选择送餐时间'
            }
            this.popupType = type
        },
        popupHandle () {
            this.showPicker = !this.showPicker
            if (!this.showPicker && this.results) {
            setTimeout(() => this.results = null, 100)
            }
            if (!this.showPicker && this.slots) {
            setTimeout(() => this.slots = null, 100)
            }
        },
        cancle() {
            this.showPicker = !this.showPicker
        },
        changeHandle (picker, values) {
            let slot1 = null
            let slot2 = null
            this.ymd.forEach(year => {
            if (year.value === values[0]) {
                slot1 = year.children.map(month => month.value)
                year.children.forEach(month => {
                if (month.value === values[1]) {
                    slot2 = month.children.map(date => date.value)
                }
                })
            }
            })
            picker.setSlotValues(1, slot1)
            picker.setSlotValues(2, slot2)
            // this.date = values.join('-')
            this.setTakeoutParams({key:'date',value:values.join('-')})
        },        
        submitHandle (value) {
            this.popupHandle()  
            if (this.popupType === 'province') {
                if(this.mealQueryType == 1) {
                    this.setProvinceCity({key:'takeoutProvince', value:value.txt})
                    this.setProvinceCity({key:'takeoutCity', value:""})
                }
                if(this.mealQueryType == 2) {
                    this.setProvinceCity({key:'mealProvince', value:value.txt})
                    this.setProvinceCity({key:'mealCity', value:""})
                } 
                this.province = value.txt                
            }
            if (this.popupType === 'city') {
                if(this.mealQueryType == 1) {
                    this.setProvinceCity({key:'takeoutCity', value:value.txt})
                }
                if(this.mealQueryType == 2) {
                    this.setProvinceCity({key:'mealCity', value:value.txt})
                }
                this.city = value.txt
                this.gps = value.itsExtData.gps;
            }
            if (this.popupType === 'mettingType') this.setMealParams({key:'mettingType',value:value})
            if (this.popupType === 'address') this.setTakeoutParams({key:'address',value:value})
            if (this.popupType === 'date') this.setTakeoutParams({key:'date',value:value})
            if (this.popupType === 'time') this.setTakeoutParams({key:'time',value:value})
          }, 
          initProvinceCity() {
            if(this.mealQueryType == 1) {
                this.province = this.takeoutProvince
                this.city = this.takeoutCity
            }
            if(this.mealQueryType == 2) {
                this.province = this.mealProvince
                this.city = this.mealCity
            }            
        }                         
    }
}