export const datePicker = (min, max) => {
    let data = range(min[0], max[0], false, '年')
    data.forEach(year => {
        let minMonth = year.value === min[0] ? min[1] : 1
        let maxMonth = year.value === max[0] ? max[1] : 12
        year.children = range(minMonth, maxMonth, false, '月')
        year.children.forEach(month => {
            let day = 30
            if ([1, 3, 5, 7, 8, 10, 12].indexOf(month.value) > -1) {
                day = 31
            } else {
                if (month.value === 2) {
                    day = !(year.value % 400) || (!(year.value % 4) && year.value % 100) ? 29 : 28
                }
            }
            let minDay = year.value === min[0] && month.value === min[1] ? min[2] : 1
            let maxDay = year.value === max[0] && month.value === max[1] ? max[2] : day
            month.children = range(minDay, maxDay, false, '日')
        })
    })
    return data
}

export const range = (n, m, polyfill = false, unit = '') => {
    let arr = []
    for (let i = n; i <= m; i++) {
        let value = (polyfill && i < 10 ? '0' + i : i) + unit
        arr.push({
            text: value,
            value: i
        })
    }
    return arr
}