export default {
	methods: {
		closeWebview() {
			let appType = this.iStorage.get('appType')
			if(appType == 'iEvent') {
				this.JSbridge.close()
			} else {
				window.webView.close()
			}
			//返回退出清空缓存，避免污染
			sessionStorage.clear()
			localStorage.clear()
		}
	}
}