//
//
//
//
//
//
//
//
//
//

import CommonQuery from "../../components/qshop/commonQuery";
import closeWebview from "../../components/qshop/closeWebview";
export default {
  extends: closeWebview,
  data() {
    return {};
  },
  components: {
    CommonQuery
  },
  computed: {
    title: function() {
      const queryType = this.iStorage.get("queryType") || "meal";
      let titleTxt  =''
      if (queryType == "meal") {
        titleTxt = "餐厅查询";
      }
      if (queryType == "venue") {
        titleTxt =  "酒店查询";
      }
      if (queryType == "car") {
        titleTxt =  "车队查询";
      }
      if (queryType == "iEvent") {
        titleTxt =  "美团商户查询";
      }
      return titleTxt
    }
  },
  methods: {
    closeqShop() {
      this.closeWebview();
    }
  }
};
